<template>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title class="text-h5 light-blue dark">
          Gericht zu Fach '{{compartment.name}}' hinzufügen.
        </v-card-title>

        <v-card-text>

        <v-list subheader two-line>
            <v-subheader >Vorhandene Gerichte</v-subheader>
            <div class="text-center">
                <v-chip @click="chip_clicked(item)" v-for="item in items" :key="item.uid" class="ma-2" close color="red" text-color="white" >{{ item.name }}</v-chip>
            </div>
            <v-divider ></v-divider>
            <v-subheader >etwas anderes</v-subheader>
            <v-form>
                <v-text-field v-model="new_frozenfood.name" label="Name des Gerichts"></v-text-field>
                <v-combobox v-model="new_frozenfood.num_servings" :items="combobox_items" label="Anzahl Portionen"></v-combobox>
                <v-text-field v-model="new_frozenfood.freeze_month" label="Monat"></v-text-field>
                <v-text-field v-model="new_frozenfood.freeze_year" label="Year"></v-text-field>
                <v-select v-model="new_frozenfood.type" hint="Hilfreich zum späteren Suchen!" :items="types" item-text="value" item-value="key" label="Art des Gerichtes" persistent-hint single-line ></v-select>
                <v-switch v-model="new_frozenfood.raw" label="noch roh"></v-switch>
                <v-switch v-model="new_frozenfood.convenience" label="Fertiggericht"></v-switch>
                <v-switch v-model="new_frozenfood.self_cooked" label="selber gekocht"></v-switch>
            </v-form>


        </v-list>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog_save_close()"> Speichern </v-btn>
          <v-btn color="primary" text @click="dialog_close()"> Abbrechen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>



<script>

//import Supply from './Supply'

  export default {
    components : {
  //      Supply,
    },
    props : {
        show        : Boolean,
        compartment : Object,
    },
    watch : {
      show : function(new_show) {
          this.dialog = new_show;
      },
    },

    data () {    
      return {
        dialog: false,
        combobox_items : ["1", "2"],
        types : [ 
            {"key" : "Main", "value": "Hauptgericht"}, 
            {"key" : "Side", "value": "Beilage"}, 
            {"key" : "Ingredient", "value": "Zutat"}, 
            {"key" : "Other", "value": "anderes"}],
        items : [ {uid : 1, personen : 2, name : "Bolognese", monat : 12, jahr : 2020} ],
        new_frozenfood : {
            user_uid        : 1, // FIXME TODO
            compartment_uid : null, //this.compartment.uid,
            name            : "",
            num_servings    : 2,
            freeze_month    : new Date().getMonth()+1,
            freeze_year     : new Date().getFullYear(),
            type            : "Main", // Main, Side, Ingredient, Other
            convenience     : false,
            raw             : false,
            self_cooked     : false,
        },
      }
    },
    methods : {
        dialog_save_close : function() {
            this.new_frozenfood.compartment_uid = this.compartment.uid;
            this.new_frozenfood.num_servings = parseInt(this.new_frozenfood.num_servings);
            this.new_frozenfood.freeze_month = parseInt(this.new_frozenfood.freeze_month);
            this.new_frozenfood.freeze_year = parseInt(this.new_frozenfood.freeze_year);
            this.$backendapi.call("frozenfood/new", this.new_frozenfood, response => {
                    console.log("called frozenfood/new");
                    console.log(response.data);
                    this.$emit("update-compartment");
                    console.log("update list");
                }, (err) => {
                    this.$store.dispatch('setError', err);
                });


            this.dialog_close();
        },
        dialog_close : function() {
            this.$emit("dialog-closed");
            this.dialog = false;
        },

        for1person : (item) => item["personen"] == 1,
        for2persons : (item) => item["personen"] == 2,
        chip_clicked : function(item) {
            this.new_frozenfood.name = item.name;           
        },
        update : function() {
            this.$backendapi.call("supply/list", this.compartment.freezer_uid, response => {
                    console.log("called supply");
                    console.log(response.data);
                    this.items = response.data;
                    console.log("update list");
                }, (err) => {
                    this.$store.dispatch('setError', err);
                });
        },
    },
    mounted : function() {
        this.update();
    },

  }
</script>
