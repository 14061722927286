<template>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card v-if="frozenfood != null">
        <v-card-title class="text-h5 light-blue dark">
          Gericht '{{frozenfood.name}}' löschen?
        </v-card-title>

        <v-card-text>
          Wurde das ausgewählte Gericht '{{frozenfood.name}}' aus dem Gefrierschrank genommen und kann nun aus der Liste gelöscht werden?.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog_save_close()"> Löschen </v-btn>
          <v-btn color="primary" text @click="dialog_close()"> Abbrechen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>



<script>

//import Supply from './Supply'

  export default {
    components : {
  //      Supply,
    },
    props : {
        show        : Boolean,
        frozenfood  : Object,
    },
    watch : {
      show : function(new_show) {
          this.dialog = new_show;
      },
    },

    data () {    
      return {
        dialog: false,
      }
    },
    methods : {
        dialog_save_close : function() {
            this.$backendapi.call("frozenfood/delete", this.frozenfood.uid, response => {
                    console.log("called frozenfood/delete");
                    console.log(response.data);
                    this.$emit("update-compartment");
                    console.log("update list");
                }, (err) => {
                    this.$store.dispatch('setError', err);
                });


            this.dialog_close();
        },
        dialog_close : function() {
            this.$emit("dialog-closed");
            this.dialog = false;
        },
    },
    mounted : function() {
    },
  }
</script>
