<template>
    <div>
        <v-card max-width="600" class="mx-auto my-10">
            <v-toolbar color="blue" dark>
                <v-toolbar-title>
                    {{ freezer.name }}             
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                    <!-- <v-icon @click="download_pdf">mdi-file-pdf-box</v-icon> -->
                    <v-icon @click="download_pdf2">mdi-file-pdf-box</v-icon>
                </v-btn>
            </v-toolbar>
        </v-card>

        <FoodFilter @filter-changed="filter_changed" />
        <Compartment v-for="fach in faecher" :key="fach.uid" :compartment="fach" :filter="filter" />
    </div>
</template>

<script>
import Compartment from './Compartment'
import FoodFilter from './FoodFilter'

  export default {
    name: 'Freezer',

    components: {
      Compartment,
      FoodFilter,
    },

    props : {
        freezer : Object,
    },

    data: function() {
      return {
          faecher : [],
          filter  : {},
      };
    },
    methods: {
        update : function() {
            this.$backendapi.call("compartment/list", this.freezer.uid, response => {
                console.log("called compartment list");
                console.log(response.data);
                this.faecher = response.data;
                console.log("update list");
            }, (err) => {
                this.$store.dispatch('setError', err);
            });
        },
        filter_changed : function(filter) {
            // console.log("CHANGE");
            this.filter = filter;
        },
        download_pdf : function() {
            this.$backendapi.call("pdf/download", this.freezer.uid, response => {
                console.log("called download pdf");
                console.log("update list");
                let blob = new Blob([response.data], { type: 'application/pdf' } ),
                    url = window.URL.createObjectURL(blob);
                window.open(url); //s

/*
                     var FILE = window.URL.createObjectURL(new Blob([response.data]));

                     var docUrl = document.createElement('x');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', 'file.pdf');
                     document.body.appendChild(docUrl);
                     docUrl.click();
*/

            }, (err) => {
                this.$store.dispatch('setError', err);
            });

        },
        download_pdf2 : function() {
            //this.$router.push('/api/pdf/' + this.freezer.uid);
            window.open('http://gefrierschrank.moenikes.net/api/pdf/' + this.freezer.uid);
        },
    },
    mounted : function() {
        this.update();
    },
  }
</script>



