<template>
    <v-card max-width="600" class="mx-auto my-10">
        <v-toolbar color="light-blue" dark>
            <v-toolbar-title>Filter</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
                <v-icon v-if="! filter_visible" @click="filter_visible = !filter_visible">mdi-window-maximize</v-icon>
                <v-icon v-if="filter_visible" @click="filter_visible = !filter_visible">mdi-window-minimize</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text v-if="filter_visible">
            <v-form>
<!--
                <v-text-field @change="filter_changed" v-model="filter.name" label="Name des Gerichts"></v-text-field>
                <v-combobox @change="filter_changed" v-model="filter.num_portions" :items="combobox_items" label="Anzahl Portionen"></v-combobox>
                <v-text-field @change="filter_changed" v-model="filter.month" label="Monat"></v-text-field>
                <v-text-field @change="filter_changed" v-model="filter.year" label="Year"></v-text-field>
                <v-select @change="filter_changed" v-model="filter.type" hint="Hilfreich zum späteren Suchen!" :items="types" item-text="value" item-value="key" label="Art des Gerichtes" persistent-hint single-line ></v-select>
-->
                <v-switch @change="filter_changed" v-model="filter.raw" label="noch roh"></v-switch>
                <v-switch @change="filter_changed" v-model="filter.convenience" label="Fertiggericht"></v-switch>
                <v-switch @change="filter_changed" v-model="filter.self_cooked" label="selber gekocht"></v-switch>
                <v-btn @click="reset"> Reset </v-btn>
            </v-form>
        </v-card-text>

    </v-card>

</template>



<script>


  export default {
    props : {
    },
    components : {
    },
    data: () => ({
        combobox_items : ["1", "2"],
        types : [ 
            {"key" : "Main", "value": "Hauptgericht"}, 
            {"key" : "Side", "value": "Beilage"}, 
            {"key" : "Ingredient", "value": "Zutat"}, 
            {"key" : "Other", "value": "anderes"}],
        filter_visible : false,
        filter : null,
    }),
    methods: {
        reset : function() {
            this.filter = {
                "name"         : "",
                "num_portions" : 0,
                "month"        : new Date().getMonth()+1,
                "year"         : new Date().getFullYear(),
                "type"         : null,// "Main",
                "raw"          : false,
                "convenience"  : false,
                "self_cooked"  : false,
            };
            this.$emit("filter-changed", this.filter);
        },
        filter_changed : function() {
            console.log(this.filter);
            this.$emit("filter-changed", this.filter);
        },
    },
    mounted : function() {
        this.reset();
    },

  }
</script>
