<template>
<div>
  <FreezerList />
</div>
</template>

<script>
  import FreezerList from '../components/manage-freezer/FreezerList'

  export default {
    name: 'ManageFreezer',

    components: {
      FreezerList,
    },
  }
</script>
