<template>
    <v-card max-width="600" class="mx-auto mb-10">
        <v-toolbar color="light-blue" dark>
   <!--         <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
            <v-toolbar-title>{{ compartment.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
   <!--                <v-icon>mdi-view-module</v-icon> -->
                <v-icon v-if="! list_visible" @click="list_visible = !list_visible">mdi-window-maximize</v-icon>
                <v-icon v-if="list_visible" @click="list_visible = !list_visible">mdi-window-minimize</v-icon>
            </v-btn>
        </v-toolbar>


        <v-list subheader two-line v-if="list_visible">
          <v-subheader>1 Person </v-subheader>
          <v-divider  ></v-divider>
          <template v-for="(item, index) in items.filter(for1person).filter(user_filter)">
            <v-list-item :key="index">
<!--            <v-list-item-avatar>  <v-img :src="item.avatar"></v-img> </v-list-item-avatar> -->
                <v-list-item-content>
                    <v-list-item-title v-html="item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="info(item)"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <div>
                        <v-btn icon @click="edit_item(item)">
                            <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon @click="delete_item_from_compartment(item)">
                            <v-icon color="grey lighten-1">mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </v-list-item-action>
            </v-list-item>
          </template>
          <v-list-item v-if="items.filter(for1person).filter(user_filter).length == 0"><v-list-item-content><v-list-item-title>Nichts gefunden</v-list-item-title></v-list-item-content></v-list-item>
        </v-list>

        <v-list subheader two-line v-if="list_visible">
          <v-subheader>2 Personen </v-subheader>
          <v-divider  ></v-divider>
          <template v-for="(item, index) in items.filter(for2persons).filter(user_filter)">
            <v-list-item :key="index">
<!--            <v-list-item-avatar>  <v-img :src="item.avatar"></v-img> </v-list-item-avatar> -->
                <v-list-item-content>
                    <v-list-item-title v-html="item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="info(item)"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <div>
                        <v-btn icon @click="edit_item(item)">
                            <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon @click="delete_item_from_compartment(item)">
                            <v-icon color="grey lighten-1">mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </v-list-item-action>
            </v-list-item>
          </template>
          <v-list-item v-if="items.filter(for2persons).filter(user_filter).length == 0"><v-list-item-content><v-list-item-title>Nichts gefunden</v-list-item-title></v-list-item-content></v-list-item>
        </v-list>

              <v-fab-transition>
                <v-btn @click="add_item_to_compartment" v-show="true" color="pink" fab dark small absolute bottom left>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-fab-transition>


<!--

        <v-list subheader two-line>
            <v-subheader inset>1 Person</v-subheader>
            <div class="text-center"> 
                <v-chip v-for="item in items.filter(for1person).filter(user_filter)" :key="item.uid" class="ma-2" close color="red" text-color="white" draggable>{{ item.name }}</v-chip> 
            </div>
            <v-divider inset></v-divider>
            <v-subheader inset>2 Personen</v-subheader>
                <v-chip v-for="item in items.filter(for2persons).filter(user_filter)" :key="item.uid" class="ma-2" close color="red" text-color="white" draggable >{{ item.name }}</v-chip> 

        </v-list>
-->

<AddFrozenFoodDialog :show="add_frozenfood_dialog_visible" @dialog-closed="add_item_to_compartment_dialog_closed()" @update-compartment="update()" :compartment="compartment" />

<DeleteFrozenFoodDialog :show="delete_frozenfood_dialog != null" @dialog-closed="delete_item_from_compartment_dialog_closed()" @update-compartment="update()" :frozenfood="delete_frozenfood_dialog" />

    </v-card>

</template>



<script>

import AddFrozenFoodDialog    from './AddFrozenfoodDialog'
import DeleteFrozenFoodDialog from './DeleteFrozenfoodDialog'

  export default {
    props : {
        compartment : Object,
        filter      : Object,
    },
    components : {
        AddFrozenFoodDialog,
        DeleteFrozenFoodDialog,
    },
    data: () => ({
        list_visible : false,
        add_frozenfood_dialog_visible : false,
        delete_frozenfood_dialog : null,
         
        items : [],
//      items : [ {uid : 1, personen : 2, name : "Bolognese", monat : 12, jahr : 2020} ],
    }),
    methods: {
        for1person : (item) => item["num_servings"] == 1,
        for2persons : (item) => item["num_servings"] == 2,
        info : (item) => ""+item.freeze_month+"/"+item.freeze_year, // +" - " + item.personen + " Personen",
        user_filter : function(item) {
/*
                 user_uid        : 1, // FIXME TODO
                "name"         : "",
                "num_portions" : 0,
                "month"        : new Date().getMonth()+1,
                "year"         : new Date().getFullYear(),
                "type"         : null,// "Main",
                "raw"          : false,
                "convenience"  : false,
                "self_cooked"  : false,
*/
            let result = true;
            if (this.filter.raw && item.raw == false) return false;
            if (this.filter.convenience && item.convenience == false) return false;
            if (this.filter.self_cooked && item.self_cooked == false) return false;
            console.log(item);
            return result;
        },
        update : function() {
            this.$backendapi.call("frozenfood/list", this.compartment.uid, response => {
                    console.log("called frozenfood/list");
                    console.log(response.data);
                    this.items = response.data;
                    this.list_visible = true;
                    console.log("update list");
                }, (err) => {
                    this.$store.dispatch('setError', err);
                });
        },
        add_item_to_compartment : function() {
            this.add_frozenfood_dialog_visible = true;
        },
        add_item_to_compartment_dialog_closed : function() {
            this.add_frozenfood_dialog_visible = false;
        },
        delete_item_from_compartment : function(item) {
            this.delete_frozenfood_dialog = item;
        },
        delete_item_from_compartment_dialog_closed : function() {
            this.delete_frozenfood_dialog = null;
        },
    },
    mounted : function() {
        this.update();
    },

  }
</script>
